<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between">
                        <div class="section-title margin-deliver">{{ $t(`Templates`) }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" v-for="template in templates">
                            <div class="template-card" :class="{selected:template.selected }" >
                                <div class="template-card-header">
                                </div>
                                <div class="template-card-body">
                                    <img :src="template.url" alt="" class="img-fluid w-100" />
                                </div>
                                <div class="template-card-footer">
                                    <h4 class="section-title">{{template.name}}</h4>
                                    <div class="action-buttons">
                                        <a href="javascript:void(0);" class="semi-button semi-button-success mr-3" @click="showCompanyPreview(template)">Preview</a>
                                        <button href="javascript:void(0);" class="semi-button semi-button-primary" :disabled="template.selected"  @click.prevent="saveCompanyTemplate(template.slug)">{{$t('Select')}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <modal-job-preview
                    :preview-url="previewUrl"
                    v-if="showPreview"
                    @closeModal="hideJobPreview"
            />
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import ModalJobPreview from "../../../components/modal/job/Preview";
import { directive as onClickaway } from 'vue-clickaway';

export default {
  components: {
    DashboardLayout,
    SettingMenu,
    ModalJobPreview
  },
  directives: {
    onClickaway: onClickaway,
  },

    data() {
        return {
            templates: [],
            showPreview: false,
            previewUrl: '',
        }
    },

    computed: {
    },
    methods: {
        async saveCompanyTemplate(slug) {
            let {data: {data, message}} = await client().post(`/company/setting/templates`, {template: slug});
            this.templates = data;
            this.$toast.success(message);
        },
        async getCompanyTemplates() {
            try {
                let {data: {data, message}} = await client().get(`/company/setting/templates`);
                this.templates = data;
            } catch (e) {
            }
        },
        showCompanyPreview(template) {
            this.previewUrl = template.preview;
            this.showPreview = true;
        },
        hideJobPreview() {
            this.showPreview = false;
            this.previewUrl = '';
        }
    },
    async mounted() {
       await this.getCompanyTemplates();
    }
}
</script>

<style scoped>
.page-body .content-area__body .settings-section .template-card {
    border: 3px solid transparent;
    margin-bottom: 30px;
}
.page-body .content-area__body .settings-section .template-card.selected {
    border-color: #597dfc;
}
button:disabled{
    background-color: #597dfc!important;
    color: #fff!important;
    box-shadow: none!important;
}
</style>
