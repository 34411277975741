<template>
    <div>
        <div class="modal fade show" style="display: block">
            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeModal">
                    <div class="modal-header">
                        <h4 class="modal-title text-capitalize">{{ $t("Preview")}}</h4>
                        <button type="button" class="close" @click="closeModal">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <iframe :src="previewUrl" name="job_preview" id="jobPreview" width="100%" height="900px" frameborder="0"></iframe>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>
<script>

    export default {
        props: ['previewUrl'],
        components: {},
        data() {
            return {}
        },
        methods: {
            closeModal() {
                this.$emit('closeModal');
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .modal-xl {
        max-width: 1600px;
    }
</style>
